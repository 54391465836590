import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  ApiResponse,
  CheckPublicIndicatorValuesUpdated,
  CheckPublicIndicatorValuesUpdatedPayload,
  GetPublicIndicatorParams,
  Indicator,
  MetricCategory,
  SearchPublicIndicatorPayload,
} from '../../models';
import { PublicApiService } from '../api/public-api.service';

@Injectable({ providedIn: 'root' })
export class PublicIndicatorsService {
  private readonly resource: string;
  private readonly servicePath: string;

  constructor(private apiService: PublicApiService) {
    this.servicePath = apiService.getServicePath();
    this.resource = apiService.apiConfig.apis.external.resources.indicators;
  }

  public checkValuesUpdated(
    payload: CheckPublicIndicatorValuesUpdatedPayload,
  ): Observable<ApiResponse<CheckPublicIndicatorValuesUpdated[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/check_values_updated`, payload);
  }

  public search(payload: SearchPublicIndicatorPayload, loadValues = false): Observable<ApiResponse<Indicator[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/search`, {
      business_unit_id: payload.business_unit_id,
      excludes: { categories: [MetricCategory.THIRD_PARTY] },
      filters: { active: null, ...payload.filters },
      fiscal_year: payload.fiscal_year,
      from: payload.from,
      keywords: payload.keywords || '',
      load_value_group_sets: loadValues,
      load_value_groups: loadValues,
      load_values: loadValues,
      load_related_equivalent: false,
      load_conditional_triggers: false,
      load_related_core_equivalent: false,
      load_framework: false,
      load_tags: false,
      load_industries: false,
      load_topics: false,
      load_standard_codes: false,
      size: payload.size,
      sort: payload.keywords ? [] : payload.sort,
    });
  }

  public getIndicator(id: string, requestParams: GetPublicIndicatorParams): Observable<ApiResponse<Indicator>> {
    const params = new HttpParams({ fromObject: requestParams });
    return this.apiService.get(`${this.servicePath}${this.resource}/indicators/${id}`, { params });
  }
}
